import store from "store";

// Waave stuff

function formatPhoneNumber(phoneNumberString) {
  // eslint-disable-next-line prefer-template
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
}

// React env
const api = process.env.REACT_APP_API;
const version = process.env.REACT_APP_API_VERSION;
const url = api + version;

const devToolsEnabled = process.env.REACT_APP_DEVTOOLS;

const GetToken = () => {
  const token = store.get("app.token");
  return token;
};

const APP_CONST = {
  api,
  version,
  url,
  GetToken,
  devToolsEnabled,
  // split
  formatPhoneNumber,
};

export default APP_CONST;
