import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import MenuPages from "./MenuPages";
// import Status from './Status'
// import LanguageSwitcher from './LanguageSwitcher'
// import Actions from './Actions'
import UserMenu from "./UserMenu";
// import Cart from './Cart'
import style from "./style.module.scss";

const mapStateToProps = ({ settings }) => ({ settings });

const MenuSimply = ({ settings: { version, menuColor } }) => {
  return (
    <div
      className={classNames(style.menuSimply, {
        [style.menuSimply__white]: menuColor === "white",
        [style.menuSimply__gray]: menuColor === "gray",
      })}
    >
      <div className={`${style.menuSimply__logo} d-md-block`}>
        {/* <div className={style.menuSimply__logo__letter}></div> */}
        {/* <div className={style.menuSimply__logo__name}>{logo}</div> */}

        <img src="/resources/images/waave/logo.svg" alt="logo" />
        <div className={style.menuSimply__logo__descr}>{version}</div>
      </div>
      <div className="mr-auto d-block">
        <MenuPages />
      </div>
      {/* <div className="mr-3 d-xl-block d-none">
        <Status />
      </div>
      <div className="mr-3 d-none d-md-block">
        <Cart />
      </div>
      <div className="mr-3 d-none d-md-block">
        <Actions />
      </div>
      <div className="mr-3 d-none d-md-block">
        <LanguageSwitcher />
      </div> */}
      <div>
        <UserMenu />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(MenuSimply);
