import { all, put, takeLatest } from "redux-saga/effects";
import request from "redux/network/actions";
import { actions } from "./actions";
import { reports } from "./api";

function* LOAD(payload) {
  yield put(request(reports(payload.payload), actions.LOAD));
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)]);
}
