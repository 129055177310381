import localeAntd from "antd/es/locale/fr_FR";

const messages = {
  "topBar.issuesHistory": "Problèmes",
  "topBar.projectManagement": "Projets",
  "topBar.typeToSearch": "Chercher...",
  "topBar.findPages": "Trouver des pages...",
  "topBar.actions": "Actes",
  "topBar.status": "Statut",
  "topBar.profileMenu.hello": "Bonjour",
  "topBar.profileMenu.billingPlan": "Plan de facturation",
  "topBar.profileMenu.role": "Rôle",
  "topBar.profileMenu.email": "Email",
  "topBar.profileMenu.phone": "Téléphone",
  "topBar.profileMenu.editProfile": "Editer le profil",
  "topBar.profileMenu.logout": "Connectez - Out",
};

export default {
  locale: "fr-FR",
  localeAntd,
  messages,
};
