import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import {
  // Link,
  withRouter,
} from "react-router-dom";
import classNames from "classnames";
// import Sidebar from '@vb/components/Sidebar'
// import SupportChat from '@vb/components/SupportChat'

import WaaveBusiness from "../../assets/logo/waave_business_green_white.png";
import WaaveHealth from "../../assets/logo/waave_health_green_white.png";
import WaaveConcierge from "../../assets/logo/waave_concierge_green_white.png";

import style from "./style.module.scss";

const mapStateToProps = ({ settings }) => ({
  // logo: settings.logo,
  // version: settings.version,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  isAuthTopbar: settings.isAuthTopbar,
  subdomain: settings.subdomain,
});

const AuthLayout = ({
  children,
  // logo,
  // version,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  isAuthTopbar,
  subdomain,
}) => {
  const pickLogo = (type) => {
    if (type === "driver") {
      return <img src={WaaveBusiness} alt="logo" style={{ height: "18px" }} />;
    }

    if (type === "fleet") {
      return <img src={WaaveHealth} alt="logo" style={{ height: "18px" }} />;
    }

    return <img src={WaaveConcierge} alt="logo" style={{ height: "18px" }} />;
  };

  return (
    <Layout className="vb__layout">
      <Layout.Content>
        {/* <Sidebar /> */}
        {/* <SupportChat /> */}

        <div
          className={classNames(`${style.container}`, {
            vb__layout__squaredBorders: isSquaredBorders,
            vb__layout__cardsShadow: isCardShadow,
            vb__layout__borderless: isBorderless,
            [style.white]: authPagesColor === "white",
            [style.gray]: authPagesColor === "gray",
          })}
          style={{
            backgroundImage: "white",
            // authPagesColor === 'image' ? 'url(resources/images/content/photos/8.jpeg)' : '',
          }}
        >
          {isAuthTopbar && (
            <div
              className={classNames(`${style.topbar}`, {
                [style.topbarGray]: isGrayTopbar,
              })}
            >
              <div className={style.logoContainer}>
                <div className={style.logo}>
                  {pickLogo(subdomain)}
                  {/* <img src="/resources/images/waave/logo.svg" alt="logo" /> */}
                  {/* <div className={style.descr}>{subdomain}</div> */}
                </div>
              </div>

              {/* <div className="d-none d-sm-block">
                <span className="mr-2">Don&#39;t have an account?</span>
                <Link to="/auth/register" className="font-size-16 vb__utils__link">
                  Sign up
                </Link>
              </div> */}
            </div>
          )}

          <div className="pr-4 pl-4" style={{ marginTop: "6rem" }}>
            {children}
          </div>

          <div
            className="mt-auto pb-5 pt-5 w-100"
            style={{
              paddingRight: "1.42rem",
              paddingLeft: "1.42rem",
            }}
          >
            <hr style={{ border: "1px solid rgba(136, 139, 140, 0.5)" }} />

            <span
              style={{
                fontFamily: "Nunito Sans, serif",
                fontSize: "12px",
                fontWeight: "normal",
                fontStyle: "normal",
                lineHeight: "16px",
                color: "#888B8C",
              }}
            >
              &#169; 2021 Waave Inc
            </span>
          </div>

          {/* <div className="mt-auto pb-5 pt-5">
            <ul
              className={`${style.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}
            >
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Compliance
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Support
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Contacts
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  About
                </a>
              </li>
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Advertising
                </a>
              </li>
            </ul>
            <div className="text-center">
              Copyright © {new Date().getFullYear()}{' '}
              <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer">
                Sellpixels.com
              </a>
              {' | '}
              <a href="https://sellpixels.com/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </div>
          </div> */}
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default withRouter(connect(mapStateToProps)(AuthLayout));
