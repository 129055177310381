import { get } from "utils/net";
import APP_CONST from "../../utils/constants";

const { url, GetToken } = APP_CONST;

const apiUrl = `${url}/reports/rides`;

export const ridesHistory = (params) => get(apiUrl, params, GetToken());
export const ridesHistoryDownload = (params) =>
  get(`${apiUrl}/download`, params, GetToken());
