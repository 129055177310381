import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Avatar } from "antd";
import styles from "./style.module.scss";

const mapStateToProps = ({ user }) => ({ user });

const ProfileMenu = ({ dispatch, user }) => {
  const logout = (e) => {
    e.preventDefault();
    dispatch({
      type: "user/LOGOUT",
    });
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />,{" "}
          {user.name || "Anonymous"}
        </strong>
        <div>
          <strong className="mr-1">
            <FormattedMessage id="topBar.profileMenu.billingPlan" />:{" "}
          </strong>
          Professional
        </div>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{" "}
          </strong>
          {user.role || "—"}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{" "}
          </strong>
          {user.email || "—"}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{" "}
          </strong>
          {user.phone || "—"}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={(e) => e.preventDefault()}>
          <i className="fe fe-user mr-2" />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div className={styles.dropdown}>
        <Avatar
          className={styles.avatar}
          shape="square"
          size="large"
          icon={<UserOutlined />}
        />
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(ProfileMenu);
