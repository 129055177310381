import { actions } from "./actions";

const initialState = {
  data: {
    trips: {
      today: {
        totalFare: 0,
        totalCount: 0,
        countCard: 0,
        totalCard: 0,
      },
      month: {
        totalFare: 0,
        totalCount: 0,
        countCard: 0,
        totalCard: 0,
      },
      year: {
        totalFare: 0,
        totalCount: 0,
        countCard: 0,
        totalCard: 0,
      },
    },
    paymentType: {
      cash: 0,
      card: 0,
    },
    labels: [],
    dataSet: [],
  },
  pagination: {},
  loading: false,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case actions.RESET:
    case actions.ERROR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Reducer;
