import { networkActions } from "redux/network/actions";

const actions = {
  ...networkActions("dashboard"),
};

const load = (payload) => {
  return {
    type: actions.LOAD,
    payload,
  };
};

const reset = () => {
  return {
    type: actions.RESET,
  };
};

export { actions, load, reset };
