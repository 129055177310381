import { postJson, get } from "../../utils/net";
import APP_CONST from "../../utils/constants";

const driverOtp = (params) =>
  postJson(`${APP_CONST.url}/auth/reports/otp`, params);
const driverLogin = (params) =>
  postJson(`${APP_CONST.url}/auth/reports/login`, params);
const userProfile = (token) =>
  get(`${APP_CONST.url}/reports/profile`, null, token);
const userResetPassword = (params) =>
  postJson(`${APP_CONST.url}/forgotPassword`, params);

export { driverOtp, driverLogin, userProfile, userResetPassword };
