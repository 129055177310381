import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user/reducers";
import menu from "./menu/reducers";
import settings from "./settings/reducers";
import ridesHistory from "./ridesHistory/reducers";
import reports from "./reports/reducers";
import dashboard from "./dashboard/reducers";

import hereRouter from "./hereRouter/reducers";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    hereRouter,
    ridesHistory,
    dashboard,
    reports
  });
