import { get } from "utils/net";
import APP_CONST from "../../utils/constants";

const { url, GetToken } = APP_CONST;

const apiUrl = `${url}/reports/dashboard`;

const dashboard = (params) => get(apiUrl, params, GetToken());
// eslint-disable-next-line import/prefer-default-export
export { dashboard };
