import localeAntd from "antd/es/locale/ru_RU";

const messages = {
  "topBar.issuesHistory": "Задания",
  "topBar.projectManagement": "Проекты",
  "topBar.typeToSearch": "Поиск...",
  "topBar.findPages": "Поиск страниц...",
  "topBar.actions": "Действия",
  "topBar.status": "Статус",
  "topBar.profileMenu.hello": "Привет",
  "topBar.profileMenu.billingPlan": "Тарифный план",
  "topBar.profileMenu.role": "Роль",
  "topBar.profileMenu.email": "Емайл",
  "topBar.profileMenu.phone": "Телефон",
  "topBar.profileMenu.editProfile": "Редактировать профиль",
  "topBar.profileMenu.logout": "Выйти",
};

export default {
  locale: "ru-RU",
  localeAntd,
  messages,
};
