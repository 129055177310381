import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Menu, Dropdown } from "antd";

import { ReactComponent as ProfileIcon } from "assets/profileIcon.svg";

import styles from "./style.module.scss";

const mapStateToProps = ({ user, settings: { menuColor } }) => ({
  user,
  menuColor,
});

const ProfileMenu = ({ dispatch, user }) => {
  const logout = (e) => {
    e.preventDefault();
    dispatch({
      type: "user/LOGOUT",
    });
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />,{" "}
          {`${user.admin_name} ${user.first_name}`}
        </strong>
        {/* <div>
          <strong className="mr-1">
            <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
          </strong>
          Professional
        </div> */}
        {/* <div> */}
        {/*  <strong>* /}
        {/*    <FormattedMessage id="topBar.profileMenu.role" />:{' '}* /}
        {/*  </strong>* /}
        {/*  {user.admin_type === 'corporate' ? capitalize(user.admin_type) : 'Coordinator'}* /}
        {/* </div> */}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{" "}
          </strong>
          {user.email}
          {/* <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {user.phone || '—'} */}
        </div>
      </Menu.Item>
      <Menu.Divider />
      {/* <Menu.Item>
        <a href="#" onClick={(e) => e.preventDefault()}>
          <i className={`${styles.menuIcon} fe fe-user`} />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </a>
      </Menu.Item>
      <Menu.Divider /> */}
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className={`${styles.menuIcon} fe fe-log-out`} />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="topRight">
      <div className={styles.dropdown}>
        <ProfileIcon />

        <span
          style={{
            marginLeft: "1rem",
            color: "#D7D7D7",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {`${user.admin_name} ${user.first_name}`}
        </span>
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(ProfileMenu);
