import { all, takeEvery, put } from "redux-saga/effects";
import { notification } from "antd";
import store from "store";
import request from "redux/network/actions";
import { history, store as reduxStore } from "index";
import { driverOtp, driverLogin, userProfile, userResetPassword } from "./api";
import { actions, profile } from "./actions";

function* OTP(payload) {
  yield put(request(driverOtp({ ...payload.payload }), actions.OTP));
}

function* LOGIN(payload) {
  const { subdomain } = yield reduxStore.getState().settings;

  yield put(
    request(driverLogin({ ...payload.payload, subdomain }), actions.LOAD)
  );
}

function* LOGOUT() {
  yield store.remove("app.token");
  yield put({
    type: actions.RESET,
  });
}

function* SUCCESS(payload) {
  const {
    payload: {
      data: { token },
    },
  } = payload;
  // Save token to localStorage
  yield store.set("app.token", token);

  yield notification.success({
    message: "Login !",
    description: "You have successfully logged in!",
  });

  yield history.push("/");
}

function* PROFILE(payload) {
  yield put(request(userProfile(payload.payload.token), actions.PROFILE));
}

// function* PROFILE_SUCCESS() {
//   yield notification.success({
//     message: 'Login !',
//     description: 'You have successfully logged in!',
//   })
// }

function* CheckToken() {
  const token = yield store.get("app.token");

  if (token) {
    yield put(profile(token));
  }
}

function* ResetPassword(payload) {
  yield put(request(userResetPassword(payload.payload), actions.RESETPASSWORD));
}

function* ResetPasswordSuccess(payload) {
  yield notification.success({
    message: "Reset password sent !",
    description: payload.payload.data.message,
  });

  yield history.push("/auth/login");
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.OTP, OTP),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.SUCCESS, SUCCESS),
    takeEvery(actions.PROFILE, PROFILE),
    // takeEvery(actions.PROFILE_SUCCESS, PROFILE_SUCCESS),
    CheckToken(),
    takeEvery(actions.RESETPASSWORD, ResetPassword),
    takeEvery(actions.RESETPASSWORD_SUCCESS, ResetPasswordSuccess),
  ]);
}
