import { all, put, call, select, takeLatest } from "redux-saga/effects";
import getMenuData from "services/menu";
import { actions as actionsUser } from "redux/user/actions";

const getSettings = (state) => state.settings;
const getUser = (state) => state.user;

export function* GET_DATA() {
  const settings = yield select(getSettings);
  const user = yield select(getUser);

  const menuData = yield call(
    getMenuData,
    settings.subdomain,
    user.admin_type,
    user.stripe_url
  );

  yield put({
    type: "menu/SET_STATE",
    payload: {
      menuData,
    },
  });
}

function* AfterProfile() {
  const settings = yield select(getSettings);
  const user = yield select(getUser);

  const menuData = yield call(
    getMenuData,
    settings.subdomain,
    user.admin_type,
    user.stripe_url
  );

  yield put({
    type: "menu/SET_STATE",
    payload: {
      menuData,
    },
  });
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
    takeLatest(actionsUser.PROFILE_SUCCESS, AfterProfile),
  ]);
}
