import { all, takeEvery, put } from "redux-saga/effects";
import request from "redux/network/actions";
import { actions } from "./actions";
import hereRouter from "./api";

function* LOAD(payload) {
  yield put(request(hereRouter(payload.payload), actions.LOAD, payload));
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD, LOAD)]);
}
