import { all } from "redux-saga/effects";
import user from "./user/sagas";
import menu from "./menu/sagas";
import settings from "./settings/sagas";
import network from "./network/sagas";
import hereRouter from "./hereRouter/sagas";
import ridesHistory from "./ridesHistory/sagas";
import reports from "./reports/sagas";
import dashboard from "./dashboard/sagas";

export default function* rootSaga() {
  yield all([
    network(),
    user(),
    menu(),
    settings(),
    hereRouter(),
    ridesHistory(),
    dashboard(),
    reports()
  ]);
}
