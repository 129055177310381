import { networkActions, extraNetworkActions } from "redux/network/actions";

const actions = {
  OTP: "user/OTP",
  LOGIN: "user/LOGIN",
  LOGOUT: "user/LOGOUT",
  ...extraNetworkActions("PROFILE", "user/profile"),
  ...networkActions("user/auth"),
  ...extraNetworkActions("RESETPASSWORD", "user/resetPassword"),
};

const otp = (payload) => {
  return {
    type: actions.OTP,
    payload,
  };
};

const login = (payload) => {
  return {
    type: actions.LOGIN,
    payload,
  };
};

const logout = () => {
  return {
    type: actions.LOGOUT,
  };
};

const profile = (token) => {
  return {
    type: actions.PROFILE,
    payload: {
      token,
    },
  };
};

const resetPassword = (params) => {
  return {
    type: actions.RESETPASSWORD,
    payload: {
      ...params,
    },
  };
};

export { actions, otp, login, logout, profile, resetPassword };
